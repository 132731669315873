const state = {
  themeLight: false,
  modalIsOpened: false,
  isMobile: false,
};

const mutations = {
  setThemeLight(state, value) {
    state.themeLight = value;
  },

  setModalIsOpened(state, value) {
    state.modalIsOpened = value;
  },

  setIsMobile(state, value) {
    state.isMobile = value;
  },
};

const actions = {};

const getters = {
  themeLight: state => state.themeLight,
  modalIsOpened: state => state.modalIsOpened,
  isMobile: state => state.isMobile,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
