const state = {
  isVisibleMainSidebar: false,
};

const mutations = {
  setIsVisibleMainSidebar(state, value) {
    state.isVisibleMainSidebar = value;
  },
};

const actions = {};

const getters = {
  isVisibleMainSidebar: state => state.isVisibleMainSidebar,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
