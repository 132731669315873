const state = {
  activeCoin: {},
};

const mutations = {
  setActiveCoin(state, coin) {
    state.activeCoin = coin;
  },

  resetActiveCoin(state) {
    state.activeCoin = {};
  },
};

const actions = {};

const getters = {
  activeCoin: state => state.activeCoin,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
