import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash, behavior: 'smooth' };
    if (savedPosition) return { ...savedPosition, behavior: 'smooth' };

    return { x: 0, y: 0, behavior: 'smooth' };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      // meta: {
      //   layout: 'Default',
      // },
      component: () =>
        import(/* webpackChunkName: "home-view" */ '@/views/HomeView.vue'),
    },
    {
      path: '/coin/:coinSymbol',
      name: 'Coin details',
      component: () =>
        import(
          /* webpackChunkName: "coin-details-view" */ '@/views/CoinDetailsView.vue'
        ),
    },
  ],
});

export default router;
