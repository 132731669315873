<template>
  <div id="app">
    <h1 class="visually-hidden">Crypto Insights</h1>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export default {
  name: 'App',
  components: {
    DefaultLayout,
  },
  data() {
    return {
      themeLight: false,
      windowWidth: 1920,
    };
  },
  computed: {
    ...mapGetters({
      isVisibleMainSidebar: 'app/isVisibleMainSidebar',
    }),
    layout() {
      return (this.$route.meta.layout || 'Default') + 'Layout';
    },
    isMobile() {
      return this.windowWidth < 768;
    },
  },
  watch: {
    themeLight(value) {
      this.$store.commit('user/setThemeLight', value);
    },
    isMobile(value) {
      this.$store.commit('user/setIsMobile', value);
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  metaInfo() {
    return {
      bodyAttrs: {
        class: [this.themeLight ? 'theme--light' : ''],
      },
    };
  },
};
</script>
