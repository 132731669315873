<template>
  <div class="application-wrapper">
    <main class="application">
      <!-- :class="{ blured: modalIsOpened, shifted: isVisibleMainSidebar }" -->
      <AppBar />
      <router-view />
      <!-- <div
        class="overlay"
        :class="{ visible: isVisibleMainSidebar }"
        @click="onCloseSidebar"
        @touchmove="preventTouchmove"
      ></div> -->
    </main>
    <!-- <MainSidebar /> -->
    <!-- <portal-target name="coin-details-modal" /> -->
  </div>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DefaultLayout',
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "app-bar" */ '@/components/AppBar/AppBar.vue'
      ),
    // MainSidebar: () =>
    //   import(
    //     /* webpackChunkName: "main-sidebar" */ '@/components/MainSidebar/MainSidebar.vue'
    //   ),
  },
  // computed: {
  //   ...mapGetters({
  //     modalIsOpened: 'user/modalIsOpened',
  //     isMobile: 'user/isMobile',
  //     isVisibleMainSidebar: 'app/isVisibleMainSidebar',
  //   }),
  // },
  // watch: {
  //   isMobile(value) {
  //     if (value) {
  //       this.setModalIsOpened(false);
  //     }
  //   },
  // },

  // methods: {
  //   ...mapMutations({
  //     setIsVisibleMainSidebar: 'app/setIsVisibleMainSidebar',
  //     setModalIsOpened: 'user/setModalIsOpened',
  //   }),
  //   onCloseSidebar() {
  //     this.setIsVisibleMainSidebar(false);
  //   },
  //   preventTouchmove(event) {
  //     event.preventDefault();
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 25, 24, 0.4);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: var(--slow-transition-duration);
  transition-timing-function: var(--slide-transition-timing-function);

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    cursor: pointer;
  }
}
</style>
